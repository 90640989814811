export * from "./content-client";
import { CONFIG } from "@/config";
import { getTenantAuth } from "@/services/tenant";
import { ContentClient } from "./content-client";

export const getHeaders = async (): Promise<Record<string, string>> => {
  // Retrieve the current user's ID token
  const user = getTenantAuth().currentUser;
  const idToken = await user?.getIdToken(); // no refresh here! Refresh is handled by useAuthUser
  if (idToken)
    return {
      Authorization: `Bearer ${idToken}`,
      "Access-Control-Max-Age": "86400",
    };
  return { "Access-Control-Max-Age": "86400" };
  // keep in mind that number of calls is limited by Firebase
};

/**
 * A client for the content engine
 */
const client = new ContentClient({
  BASE: CONFIG["CONTENT_ENGINE"],
  HEADERS: getHeaders,
  WITH_CREDENTIALS: true,
});

export default client;
