import { CONFIG } from "@/config";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, useActionData, useNavigation } from "react-router-dom";
import { ActionData } from "./createAction";
import SignInButton from "./SignInButton";

type MicrosoftSSO = {
  label: string;
  type: "microsoft";
  tenant: string;
  domain?: never;
};

type GoogleSSO = {
  label: string;
  type: "google";
  domain: string;
  tenant?: never;
};

type SSOTenantList = (MicrosoftSSO | GoogleSSO)[];
export type SSOPanelProps = {
  sso?: SSOTenantList;
};
export default function SSOPanel({ sso = DEFAULT_SSO_TENANTS }: SSOPanelProps) {
  const { t } = useTranslation();
  const showDebugAzureAD = useFeatureFlagEnabled("debug-azure-ad");
  const actionData = useActionData() as ActionData | undefined;
  const { state, formData } = useNavigation();
  const { error, type } = actionData ?? {};
  const hasSSOError = ["microsoft", "google"].includes(type!);
  const hasUserExistsError =
    hasSSOError &&
    error?.code === "auth/account-exists-with-different-credential";

  const [stayLoggedIn, setStayLoggedIn] = useState(false);

  return (
    <div className="mt-6 grid grid-cols-3 gap-3">
      {hasSSOError &&
        (hasUserExistsError ? (
          <div className="col-span-3">
            <p className="sm:text-sm">
              <ExclamationTriangleIcon className="mx-1 inline h-4 w-4 text-red-600" />
              {t("je-hebt-al-een-account-met-dit-email-adres")}
              <a
                className="mx-1 text-blue-500 hover:text-blue-600 hover:underline sm:text-sm"
                href={createMailTo()}
              >
                {t("contacteer-tiro-health-om-je-account-te-switchen")}
              </a>
            </p>
          </div>
        ) : (
          <p className="col-span-3 text-red-600">{error?.message}</p>
        ))}
      {sso?.map(({ label, type, domain, tenant }) => (
        <div key={label} className="col-span-3">
          <Form method="POST">
            <SignInButton
              type="submit"
              name="type"
              value={type}
              loading={isSubmitting(state, formData, type, domain, tenant)}
              disabled={state == "submitting"}
            >
              {label}
            </SignInButton>
            {domain && <input type="hidden" name="domain" value={domain} />}
            {tenant && <input type="hidden" name="tenant" value={tenant} />}
            <input
              type="hidden"
              name="stay-logged-in"
              value={stayLoggedIn ? "true" : "false"}
            />
          </Form>
        </div>
      ))}
      <div className="col-span-3">
        <form method="GET" action={`${CONFIG.AUTH_SERVER}/entra/login`}>
          <SignInButton type="submit" disabled={state == "submitting"}>
            @uzleuven.be
          </SignInButton>
          <input type="hidden" name="next" value={window.location.href} />
          <input type="hidden" name="provider" value="uzleuven" />
        </form>
      </div>
      <div className="col-span-3">
        <form method="GET" action={`${CONFIG.AUTH_SERVER}/entra/login`}>
          <SignInButton type="submit" disabled={state == "submitting"}>
            @mijnziekenhuis.be
          </SignInButton>
          <input type="hidden" name="next" value={window.location.href} />
          <input type="hidden" name="provider" value="azmm" />
        </form>
      </div>
      {(showDebugAzureAD || CONFIG.isDev) && (
        <div className="col-span-3">
          <form method="GET" action={`${CONFIG.AUTH_SERVER}/entra/login`}>
            <SignInButton type="submit" disabled={state == "submitting"}>
              @tiro.health (Microsoft Entra)
            </SignInButton>
            <input type="hidden" name="next" value={window.location.href} />
            <input type="hidden" name="provider" value="tiro-entra" />
          </form>
        </div>
      )}
      <div className="col-span-3 flex items-center">
        <input
          id="sso-stay-logged-in"
          type="checkbox"
          checked={stayLoggedIn}
          onChange={(event) => setStayLoggedIn(event.target.checked)}
          className="h-4 w-4 rounded border-gray-light text-blue-primary focus:ring-blue-500"
        />
        <label
          htmlFor="sso-stay-logged-in"
          className="ml-2 block text-sm text-gray-700"
        >
          {t("blijf-ingelogd-op-deze-computer")}
        </label>
      </div>
    </div>
  );
}

const DEFAULT_SSO_TENANTS: SSOTenantList = [
  {
    label: "@cumulus.uzleuven.be",
    type: "google",
    domain: "cumulus.uzleuven.be",
  },
  {
    label: "@azturnhout.be",
    type: "microsoft",
    tenant: "ff4ac274-93c0-4069-b779-7839a6752afe",
  },
  {
    label: "@asz.be",
    type: "microsoft",
    tenant: "f946a335-43c8-4df1-ac5b-0b9ddf386e35",
  },
  {
    label: "@yperman.net",
    type: "microsoft",
    tenant: "d933febe-2173-4980-b801-4f6dbb3b96af",
  },
  {
    label: "@azgroeninge.be",
    type: "microsoft",
    tenant: "70058709-1b1d-4211-b0b4-831d756a9922",
  },
  {
    label: "@tiro.health",
    type: "google",
    domain: "tiro.health",
  },
];

function createMailTo() {
  const subject = "Wijzigen van login methode.";
  const message = encodeURI(`Beste,
  Op dit moment log ik in met email/passwoord maar ik zou graag inloggen via Single-Sign-On van het ziekenhuis.
  Mijn email adres is: <vul hier je email adres in>

  Vriendelijke groeten,

  Axel`);
  return `mailto:admin@tiro.health?subject=${subject}&body=${message}`;
}

function isSubmitting(
  state: "idle" | "loading" | "submitting",
  formData: FormData | undefined,
  type: string | undefined,
  domain: string | undefined,
  tenant: string | undefined,
) {
  return (
    state === "submitting" &&
    formData?.get("type") === type &&
    formData?.get("domain") === domain &&
    (formData?.get("tenant") === tenant || !formData?.get("tenant"))
  );
}
